import { Component } from '@angular/core';
// import { get } from 'aws-amplify/api';
import { HttpClient } from '@angular/common/http';
import {AuthService} from "../auth/auth.service";


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

constructor(private http: HttpClient,private auth:AuthService) {}


}
