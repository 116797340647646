import {NavItem} from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Add Order',
    iconName: 'aperture',
    route: '/order/add-order',
  },
  {
    displayName: 'Add Test',
    iconName: 'circle-plus',
    route: '/biotest/add-biotest',
  },
  {
    displayName: 'Orders',
    iconName: 'folder-plus',
    route: 'orders',
    children: [
      {
        displayName: 'Add Order',
        iconName: 'aperture',
        route: '/order/add-order',
      },
      {
        displayName: 'List Orders',
        iconName: 'list',
        route: '/order/list',
      },
      {
        displayName: 'View Order',
        iconName: 'device-tablet',
        route: '/order/get',
      },
    ],
  },
  {
    displayName: 'Accounts',
    iconName: 'building-hospital',
    route: 'account',
    children: [
      {
        displayName: 'Add Account',
        iconName: 'circle-plus',
        route: '/account/add-account',
      },
      {
        displayName: 'List Accounts',
        iconName: 'list',
        route: '/account/list',
      },
      {
        displayName: 'Get Account',
        iconName: 'device-tablet',
        route: '/account/get',
      },
    ],
  },
  {
    displayName: 'Bio tests',
    iconName: 'report-medical',
    route: 'biotest',
    children: [
      {
        displayName: 'Add Test',
        iconName: 'circle-plus',
        route: '/biotest/add-biotest',
      },
      {
        displayName: 'List Tests',
        iconName: 'list',
        route: '/biotest/list',
      },
      {
        displayName: 'Get Account',
        iconName: 'device-tablet',
        route: '/account/get',
      },

    ],
  },
  {
    displayName: 'Physicians',
    iconName: 'stethoscope',
    route: 'physicians',
    children: [
      {
        displayName: 'Add Physician',
        iconName: 'aperture',
        route: '/physician/add-physician',
      },
      {
        displayName: 'List Physicians',
        iconName: 'list',
        route: '/physician/list',
      },
      {
        displayName: 'Get Physician',
        iconName: 'device-tablet',
        route: '/physician/get',
      },
    ],
  },

  {
    displayName: 'Patients',
    iconName: 'user-plus',
    route: 'patients',
    children: [
      {
        displayName: 'Add Patient',
        iconName: 'aperture',
        route: '/patient/add-patient',
      },
      {
        displayName: 'List Patients',
        iconName: 'list',
        route: '/patient/list',
      },
      {
        displayName: 'View Patient',
        iconName: 'device-tablet',
        route: '/patient/view',
      },
    ],
  },


];
