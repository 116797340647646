import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from "../viBytes/auth/auth.service";
import {environment} from "../../environments/environment";
import {FILE_UPLOAD} from "../constants/apiPaths";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const envName=environment.name;

     let envi = {
      name: "prod",
      production: "production",
      apiUrl: 'https://server.labbytes.vibytes.com',
      UserPoolId: 'us-east-1_i3BiwIXNI',
      ClientId: '70fovs99lnubpmjvd48ieprrut'
    };

    let modifiedReq = req.clone({});

    let contentType: string = modifiedReq.headers.get('Content-Type') || 'application/json'; // Handle null case
    const newHeaders = modifiedReq.headers.set('Content-Type', contentType);
    let token: string = "";
    if (environment.name != "local") {
      token = this.auth.getToken();
    }
    const apiUrl = environment.apiUrl;
    let apiKey = '001test'
    if (environment.name == "prod") {
      apiKey = '002hdri'
    }else if (environment.name == "dev") {
      apiKey = '001test'
    }
    modifiedReq = req.clone({
      headers: newHeaders,
      setHeaders: {
        'Authorization': token,
        'X-Api-Key': apiKey,
      }
    });

    if (req.url.startsWith(FILE_UPLOAD)) {
      modifiedReq = modifiedReq.clone({
        setHeaders: {
          'Accept': '*/*',
        }
      })
    }
    if (req.url.startsWith('/')) {
      modifiedReq = modifiedReq.clone({
          url: apiUrl + req.url
        }
      );
    }
    // Add headers or perform other modifications
    return next.handle(modifiedReq);
  }
}
