export const MEMBERS = '/physicians';
export const MEMBERS_SEARCH = '/physicians/search';
export const ACCOUNTS = '/accounts';
export const ACCOUNTS_SEARCH = '/accounts/search';
export const PATIENTS = '/patients';
export const PATIENTS_SEARCH = '/patients/search';
export const BIOTESTS = '/biotests';
export const BIOTESTS_SEARCH = '/biotests/search';
export const LIST_BIOTEST = '/biotests/list';

export const LIST_SPECIMEN_TYPES = '/datas/specimen_fields/search';

export const LIST_FIELD_TYPES = '/datas/field_types/search';

export const ORDERS = '/orders';
export const LIST_ORDERS = '/orders/list';

export const FILE_UPLOAD = '/files';

export const EXTRACTRESULT = '/extractresult';
export const GENERATEREPORT = '/reports/generate';
