import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import {environment} from "../../../environments/environment";


@Injectable({providedIn:'root'})
export class AuthorizeGuard implements CanActivate{
  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router)
  {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(environment.name == "local") return true;

    if(!this.authService.isTokenExpired())
      return true;
    else
      return  this.router.navigate(['/authentication/side-login']);

  }
}
