import {Component, Inject, OnInit} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {Router} from "@angular/router";

import {CoreService} from "../../../services/core.service";
import {AsyncPipe} from "@angular/common";
import {tap} from "rxjs";
import {AuthService} from "../../../viBytes/auth/auth.service";

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    MatButton,
    MatCheckbox,
    MatHint,
    MatInput,
    MatLabel,
    AsyncPipe,
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit {

  // options = this.settings.getOptions();
  protected showLogin: Promise<boolean>= Promise.resolve(false);
  // new Promise(resolve => {});
  constructor(private settings: CoreService, public authService:AuthService,public router:Router) {}

  ngOnInit(): void {

    // this.authService.user$.subscribe(
    //   (profile) => (console.log(JSON.stringify(profile, null, 2))),
    // );
    //
    // const jwt = this.authService.idTokenClaims$.subscribe(claims => {
    //   if (claims) {
    //     const idToken = claims.__raw;
    //     console.log("JWT:", idToken);
    //   }
    // })
    //
    // this.authService
    //   .handleRedirectCallback()
    //   .subscribe(() => {
    //       this.router.navigate(['/dashboard']);
    //   });
    //
    // this.authService.isAuthenticated$.subscribe(isAuthenticatedVal => {
    //   this.showLogin = Promise.resolve(!isAuthenticatedVal);
    //   if(isAuthenticatedVal){
    //     this.router.navigate(["/dashboard"]);
    //   }
    // });

  }


  // navigateToLogin() {
  //   this.authService.loginWithRedirect();
  // }

}
