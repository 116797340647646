import { Component } from '@angular/core';
import {CoreService} from "../../services/core.service";
// import {AuthService} from "@auth0/auth0-angular";
import {Router} from "@angular/router";

@Component({
  selector: 'app-auth-validate',
  standalone: true,
  imports: [],
  templateUrl: './auth-validate.component.html',
  styleUrl: './auth-validate.component.scss'
})
export class AuthValidateComponent {

  constructor(private settings: CoreService,
              // public authService:AuthService,
              public router:Router) {

    // this.authService.user$.subscribe(
    //   (profile) => (console.log(JSON.stringify(profile, null, 2))),
    // );


  }

  ngOnInit(): void {

    // this.authService.user$.subscribe(
    //   (profile) => (console.log(JSON.stringify(profile, null, 2))),
    // );
    //
    // this.authService.isAuthenticated$.subscribe(isAuthenticatedVal => {
    //   console.log('this.isAuthenticated::'+isAuthenticatedVal);
    //
    // });
  }

}
