import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BlankComponent} from './layouts/blank/blank.component';
import {FullComponent} from './layouts/full/full.component';
import {DashboardComponent} from "./viBytes/dashboard/dashboard.component";

import {LandingComponent} from "./pages/authentication/landing/landing.component";
import {AuthValidateComponent} from "./viBytes/auth-validate/auth-validate.component";
import {LogoutComponent} from "./viBytes/logout/logout.component";
import {AuthorizeGuard} from "./viBytes/auth/auth.guard";


import {AccountModule} from "./viBytes/account/account.module";

const routes: Routes = [
  {
    path: 'landing',
    component: LandingComponent
  },

  {
    path: 'logout',
    component: LogoutComponent  ,
    // canActivate: [AuthorizeGuard],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        component: FullComponent,
        canActivate: [AuthorizeGuard],
        children: [


          {
            path: '',
            redirectTo: '/dashboard',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            component: DashboardComponent,
          },
          {
            path: 'authValidate',
            component: AuthValidateComponent
          },
          {
            path: 'starter',
            loadChildren: () =>
              import('./pages/pages.module').then((m) => m.PagesModule),
          },
          {
            path: 'account',
            loadChildren: () =>
              import('./viBytes/account/account.module').then((m) => m.AccountModule),
          },
          {
            path: 'order',
            loadChildren: () =>
              import('./viBytes/order/order.module').then((m) => m.OrderModule),
          },
          {
            path: 'physician',
            loadChildren: () =>
              import('./viBytes/physician/physician.module').then((m) => m.PhysicianModule),
          },
          {
            path: 'patient',
            loadChildren: () =>
              import('./viBytes/patient/patient.module').then((m) => m.PatientModule),
          },
          {
            path: 'biotest',
            loadChildren: () =>
              import('./viBytes/biotest/biotest.module').then((m) => m.BiotestModule),
          },

        ],
      },
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: 'kit-order',
        loadChildren: () =>
          import('./viBytes/kit-order/kit-order.module').then((m) => m.KitOrderModule),
      },

    ],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
