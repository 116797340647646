import {Component, Inject, OnInit} from '@angular/core';

// Import the AuthService type from the SDK

import {DOCUMENT} from "@angular/common";
import {MaterialModule} from "../../material.module";
import {AuthService} from "../auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styles: [],
  standalone: true,
  imports:[MaterialModule]
})
export class LogoutComponent implements OnInit{
  // Inject the authentication service into your component through the constructor
  constructor(@Inject(DOCUMENT) private doc: Document,private authService: AuthService,private router: Router) {}

  ngOnInit() {
  }

  logout(): void {
    this.authService.onSignOut().then(aa=>
      // this.router.navigate([""])
      window.location.reload()
  );
  }
}
