import {Injectable, OnInit} from "@angular/core";
import {CognitoIdentityServiceProvider} from 'aws-sdk';
import {AuthenticationDetails, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import {jwtDecode} from "jwt-decode";
import {Router} from "@angular/router";
import {AppSideLoginComponent} from "../../pages/authentication/side-login/side-login.component";
import {environment} from "../../../environments/environment";



@Injectable({providedIn: 'root'})
export class AuthService
{
  private authData: any| null;
  constructor(private router: Router) {
    const poolData = {
      UserPoolId: environment.UserPoolId,
      ClientId: environment.ClientId
    };
    this.userPool = new CognitoUserPool(poolData);
  }
  private userPool:CognitoUserPool;


  login(username: string, password: string):Promise<boolean> {
    return new Promise((resolve, reject) => {
      const authenticationData = {
        Username: username,
        Password: password
      };

      const authenticationDetails = new AuthenticationDetails(authenticationData);
      const userData = {
        Username: username,
        Pool: this.userPool
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          console.log('Authentication successful', session);
          const idToken = session.getIdToken().getJwtToken();
          this.setToken(idToken);

          resolve(true);
        },
        onFailure: (err) => {
          console.error('Authentication failed', err);
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          console.log('New password required');
          this.setNewPassword(cognitoUser, '');
        }
      });

    })
  }
  setNewPassword(cognitoUser: any, newPassword: string) {
    cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: (session:any) => {
        console.log('New password set successfully', session);
      },
      onFailure: (err:any) => {
        console.error('Failed to set new password', err);
      }
    });
  }

  setToken(token: string) {
    this.authData=jwtDecode(token);
    localStorage.setItem('id_token', token);
  }


  public getToken(): string {
    const token = localStorage.getItem('id_token');
    if (!token) {
      throw new Error('Token not found in localStorage');
    }
    return token;
  }


  getExpiryTime() {
    if(!this.authData){
      this.setToken(this.getToken())
    }
    return this.authData ? this.authData.exp : false;
  }

  isTokenExpired(): boolean {
    try {
      const expiryTime: number = this.getExpiryTime();
      if (expiryTime) {
        var d = new Date(expiryTime * 1000); // The 0 there is the key, which sets the date to the epoch
        return (d.getTime() - (new Date()).getTime()) < 10000;
      } else {
        return true;
      }
    }catch (ex){
      return true;
    }
  }

  public async onSignOut()
  {
    this.authData=null;
    localStorage.removeItem('id_token');
  }


}
