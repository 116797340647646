import {ErrorHandler, Injectable} from "@angular/core";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";

@Injectable()
export class PopupService {

  constructor(private snackBar:MatSnackBar) {
  }
  showError(message:string){
    this.snackBar.open(message, 'Close', {
      duration: 5000, // Duration in milliseconds
      horizontalPosition: 'center', // Horizontal position of the snackbar
      verticalPosition: 'bottom', // Vertical position of the snackbar
      panelClass: [ 'mat-warn'] //
    });
  }
}
