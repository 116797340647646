import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {PopupService} from "./popup.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private router: Router,private popupService:PopupService) {}

  handleError(error: any): void {
    console.error('An error occurred:', error);
    this.popupService.showError(error);

    // Navigate to the appropriate error page based on the error
    // if (error.status === 404) {
    //   this.router.navigate(['/error/404']);
    // } else {
    //   this.router.navigate(['/error/500']);
    // }
  }
}
